import React from 'react';
import Link from 'components/utils/Link';

const LegalLinks = () => (
  <div className="legal-links">
    <div>
      <Link to="/apostrophe-privacy-policy/">Privacy Policy</Link>
      <Link to="/terms-of-service/">Terms</Link>
    </div>
    <div className="legal-links-long-text">
      <Link to="/consumer-health-data-privacy-policy/">
        Consumer Health Data Privacy Policy
      </Link>
      <Link to="/medical-notice-of-privacy-practices/">
        Medical Notice of Privacy Practices
      </Link>
      <Link to="/telehealth-consent/">Telehealth Consent</Link>
      <Link to="/open-payments-notice/">Open Payments Notice</Link>
    </div>
  </div>
);

export default LegalLinks;
